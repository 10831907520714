var Handlebars = require('/fc/workspace-data/code/node_modules/handlebars/runtime.js');
module.exports = (Handlebars['default'] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"license") : stack1),"===","4",{"name":"compare","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":57,"column":14}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n    <div class=\"pd-top-lv1 pd-bottom-lv3\" id=\"icon-lincense\">\n        <div class=\"license font-sm\">	\n          <div class=\"mg-bottom-lv3 editorial-license\">\n            <h6 class=\"font-md medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_editorial_license") : stack1), depth0))
    + "</h6>\n            <span class=\"tooltip tooltip--right\">\n              <a href=\"https://flat.toolsmeen.io/media/license/license_editorial.pdf\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n                <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n              </a>\n              <div class=\"tooltip__content\">\n                <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n              </div>\n            </span>\n            <br>\n            <span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"editorial_license_text") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n          </div>\n\n        </div>\n      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UserSession") : stack1)) != null ? lookupProperty(stack1,"userInfo") : stack1)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1),"==",1,{"name":"compare","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":24,"column":4},"end":{"line":56,"column":18}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <div class=\"fi-premium\">\n          <h6 class=\"font-sm mg-bottom-lv1 medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_premium_license") : stack1), depth0))
    + "</h6>\n          <span class=\"tooltip tooltip--right\">\n            <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"BASE_URL") : stack1), depth0))
    + "/license/icon/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n              <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n            </a>\n            <div class=\"tooltip__content\">\n              <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n            </div>\n          </span>\n          <p class=\"font-sm mg-none mg-top-lv1-i\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_license_allow") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n        </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"fi-not-premium\">\n          <h6 class=\"font-sm mg-bottom-lv1 medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_license_title") : stack1), depth0))
    + "</h6>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UserSession") : stack1)) != null ? lookupProperty(stack1,"userInfo") : stack1)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"!=",0,{"name":"compare","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":10},"end":{"line":49,"column":22}}})) != null ? stack1 : "")
    + "          <p class=\"font-sm\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_license") : stack1), depth0))
    + " <a href=\"#\" class=\"link--normal track modal__trigger mg-bottom-lv2\" data-modal=\"modal-license\" data-track-arguments=\"all, event, detail-view, click-flaticon-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"more_info") : stack1), depth0))
    + "</a></p>\n          <div class=\"row mg-none\">\n            <p class=\"font-sm mg-none mg-right-lv1-i medium attr-required\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"necessary_credit_author") : stack1), depth0))
    + "</p>\n            <button class=\"font-sm bj-button bj-button--link modal__trigger track\" title=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit_authorship") : stack1), depth0))
    + "\" data-type=\"icon\" data-track-arguments=\"all, event, detail-view, click-how-attribute, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-modal=\"modal-attribution-new\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit") : stack1), depth0))
    + "</button>\n          </div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <span class=\"tooltip tooltip--right\">\n              <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"global") : depth0)) != null ? lookupProperty(stack1,"BASE_URL") : stack1), depth0))
    + "/license/icon/"
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n                  <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n              </a>\n              <div class=\"tooltip__content\">\n                  <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n              </div>\n            </span>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n  <div class=\"pd-top-lv1 pd-bottom-lv3\" id=\"icon-lincense\">\n    <div class=\"license font-sm\">	\n          \n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"selection") : stack1),"==",0,{"name":"compare","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":64,"column":8},"end":{"line":172,"column":20}}})) != null ? stack1 : "")
    + "            \n    </div>\n  </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"license") : stack1),"===","4",{"name":"compare","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":65,"column":10},"end":{"line":91,"column":22}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"mg-bottom-lv3 editorial-license\">\n              <h6 class=\"font-md medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_editorial_license") : stack1), depth0))
    + "</h6>\n              <span class=\"tooltip tooltip--right\">\n                <a href=\"#\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n                  <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n                </a>\n                <div class=\"tooltip__content\">\n                  <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n                </div>\n              </span>\n              <br>\n              <span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"editorial_license_text") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n            </div>\n            \n            <div class=\"row mg-none mg-top-lv1-i\">\n              <p class=\"mg-none mg-right-lv1-i medium attr-required\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"necessary_credit_author") : stack1), depth0))
    + "</p>\n              <button class=\"bj-button bj-button--link modal__trigger track\" title=\""
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"sticker") : stack1),{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":82,"column":84},"end":{"line":82,"column":262}}})) != null ? stack1 : "")
    + "\" data-type=\"icon\" data-track-arguments=\"all, event, detail-view, click-how-attribute, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-modal=\"modal-attribution-new\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit") : stack1), depth0))
    + "</button>\n            </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit_authorship_stickers") : stack1), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit_authorship") : stack1), depth0));
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h6 class=\"font-md mg-none medium\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"license") : stack1), depth0))
    + " <a class=\"link--normal track\" href=\"http://creativecommons.org/licenses/by/3.0/\" title=\"CC 3.0 BY\" data-track-arguments=\"all, event, detail-view, click-flaticon-license, 110912\">\n                CC 3.0 BY.</a></h6>\n            <div class=\"row mg-none mg-top-lv1-i\">\n                <p class=\"mg-none mg-right-lv1-i medium attr-required\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"necessary_credit_author") : stack1), depth0))
    + "</p>\n                <button class=\"bj-button bj-button--link modal__trigger track\" title=\""
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"sticker") : stack1),{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":89,"column":86},"end":{"line":89,"column":264}}})) != null ? stack1 : "")
    + "\" data-type=\"icon\" data-track-arguments=\"all, event, detail-view, click-how-attribute, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-modal=\"modal-attribution-new\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit") : stack1), depth0))
    + "</button>\n            </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UserSession") : stack1)) != null ? lookupProperty(stack1,"userInfo") : stack1)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1),"==",1,{"name":"compare","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":94,"column":10},"end":{"line":170,"column":22}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"license") : stack1),"===","4",{"name":"compare","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":95,"column":12},"end":{"line":128,"column":24}}})) != null ? stack1 : "")
    + "            \n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"mg-bottom-lv3 editorial-license\">\n                <h6 class=\"font-md medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_editorial_license") : stack1), depth0))
    + "</h6>\n                <span class=\"tooltip tooltip--right\">\n                  <a href=\"#\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n                    <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n                  </a>\n                  <div class=\"tooltip__content\">\n                    <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n                  </div>\n                </span>\n                <br>\n                <span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"editorial_license_text") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n              </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"fi-premium\">\n                  <h6 class=\"font-sm mg-none medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_premium_license") : stack1), depth0))
    + "</h6>\n                  <span class=\"tooltip tooltip--right\">\n                    <a href=\"#\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n                      <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n                    </a>\n                    <div class=\"tooltip__content\">\n                      <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n                    </div>\n                  </span>\n                  <p class=\"mg-none mg-top-lv1-i\">"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_license_allow") : stack1), depth0)) != null ? stack1 : "")
    + "</p>\n                </div>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UserSession") : stack1)) != null ? lookupProperty(stack1,"userInfo") : stack1)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"premium") : stack1),"==",0,{"name":"compare","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":16},"end":{"line":127,"column":28}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"fi-not-premium\">\n                  <h6 class=\"font-md mg-none medium\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_premium_license") : stack1), depth0))
    + "</h6>\n                  <p class=\"mg-none mg-top-lv1-i\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_premium_license_expl") : stack1), depth0))
    + " <a href=\"#\" class=\"link--normal track modal__trigger mg-bottom-lv2\" data-modal=\"modal-license\" data-track-arguments=\"all, event, detail-view, click-flaticon-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"more_info") : stack1), depth0))
    + "</a></p>\n                </div>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"license") : stack1),"===","4",{"name":"compare","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data,"loc":{"start":{"line":131,"column":12},"end":{"line":169,"column":24}}})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"mg-bottom-lv3 editorial-license\">\n                <h6 class=\"font-md medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_editorial_license") : stack1), depth0))
    + "</h6>\n                <span class=\"tooltip tooltip--right\">\n                  <a href=\"#\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n                    <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n                  </a>\n                  <div class=\"tooltip__content\">\n                    <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n                  </div>\n                </span>\n                <br>\n                <span>"
    + ((stack1 = alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"editorial_license_text") : stack1), depth0)) != null ? stack1 : "")
    + "</span>\n              </div>\n              \n              <div class=\"row mg-none mg-top-lv1-i\">\n                <p class=\"mg-none mg-right-lv1-i medium attr-required\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"necessary_credit_author") : stack1), depth0))
    + "</p>\n                <button class=\"bj-button bj-button--link modal__trigger track\" title=\""
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"sticker") : stack1),{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":148,"column":86},"end":{"line":148,"column":264}}})) != null ? stack1 : "")
    + "\" data-type=\"icon\" data-track-arguments=\"all, event, detail-view, click-how-attribute, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-modal=\"modal-attribution-new\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit") : stack1), depth0))
    + "</button>\n              </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"fi-not-premium\">\n                <h6 class=\"font-sm mg-none medium inline-block\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_license_title") : stack1), depth0))
    + "</h6>\n"
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = ((stack1 = ((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"globalInfo") : depth0)) != null ? lookupProperty(stack1,"UserSession") : stack1)) != null ? lookupProperty(stack1,"userInfo") : stack1)) != null ? lookupProperty(stack1,"session") : stack1)) != null ? lookupProperty(stack1,"id") : stack1),"!=",0,{"name":"compare","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":16},"end":{"line":162,"column":28}}})) != null ? stack1 : "")
    + "                <p>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"flaticon_license") : stack1), depth0))
    + " <a href=\"#\" class=\"link--normal track modal__trigger mg-bottom-lv2\" data-modal=\"modal-license\" data-track-arguments=\"all, event, detail-view, click-flaticon-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"more_info") : stack1), depth0))
    + "</a></p>\n                <div class=\"row mg-none\">\n                  <p class=\"mg-none mg-right-lv1-i medium attr-required\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"necessary_credit_author") : stack1), depth0))
    + "</p>\n                  <button class=\"bj-button bj-button--link modal__trigger track\" title=\""
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias4).call(alias3,((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==",((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceTypes") : depth0)) != null ? lookupProperty(stack1,"sticker") : stack1),{"name":"compare","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":166,"column":88},"end":{"line":166,"column":266}}})) != null ? stack1 : "")
    + "\" data-type=\"icon\" data-track-arguments=\"all, event, detail-view, click-how-attribute, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" data-modal=\"modal-attribution-new\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"how_to_credit") : stack1), depth0))
    + "</button>\n                </div>\n              </div>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <span class=\"tooltip tooltip--right\">\n                    <a href=\"#\" class=\"tooltip__trigger tooltip__trigger--always nostyle track\" data-track-arguments=\"all, event, detail-view, download-license, "
    + alias2(alias1(((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"id") : stack1), depth0))
    + "\" target=\"_blank\">\n                        <i class=\"icon icon--download inline-block text__general--heading mg-left-lv1 font-xs\"></i>\n                    </a>\n                    <div class=\"tooltip__content\">\n                        <div class=\"content\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"messages") : depth0)) != null ? lookupProperty(stack1,"detail") : stack1)) != null ? lookupProperty(stack1,"download_license") : stack1), depth0))
    + "</div>\n                    </div>\n                  </span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"resourceData") : depth0)) != null ? lookupProperty(stack1,"type") : stack1),"==","uicon",{"name":"compare","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":176,"column":12}}})) != null ? stack1 : "");
},"useData":true});