import { Adobe, AdobeVM } from '@fc/sponsor_front';
import { ww } from 'bobjoll/ts/library/dom';
import { SponsorBanner, SponsorBannerSettings } from 'CommonApp/sponsor/sponsor-banner';
import { normalizeString } from 'Library/helpers';
import { stringEncode } from 'Library/helpers/text';
import { cookie } from 'Project/ts/library/cookie';

let templateInner: Function = require('Project/ts/templates/banner/sponsor-banner-adobe.hbs');

export const getAdobeBanner = async (options: SponsorBannerSettings) => {
    options.term = normalizeString(options.term);

    getBannerFromLibrary(options)
        .then(function(data: AdobeVM.AdobeResponseBanner) {
            if (ww.view.section == '404') {
                templateInner = require('Project/ts/templates/banner/sponsor-banner-adobe-404.hbs');
            }

            options.element.innerHTML = templateInner({
                ...data,
                messages: messages,
                URL_STATIC_IMG,
            });

            return { data: data, section: options.section, term: options.term };
        })
        .then(function(response): void {

            const container: ParentNode =
                response.section == 'detail' ? (document.getElementById('detail') as HTMLElement) : document;

            if (container === null) {
                return;
            }

            const visibleSponsorElements = Array.from(SponsorBanner.getVisibleElements());

            

            const elements: string[] = visibleSponsorElements
                .map(element => element.querySelector('img')?.alt)
                .filter(element => Boolean(element)) as string[];

            ['click', 'auxclick'].forEach(eventType => {
                container.querySelectorAll('#unicorn .item').forEach((element: HTMLElement, index: number) => {
                    element?.addEventListener(eventType, (e: Event) => {
                        const element = e.currentTarget as HTMLElement;
                        if (element && index) {
                            if ('results' in response.data && response.data.results) {
                                printCustomPixel(`${response.data.results[index].trackerClickCustom}`);
                            }
                        }
                    });
                });

                container.querySelector('.unicorn__more')?.addEventListener(eventType, () => {
                    if ('showMore' in response.data) {
                        printCustomPixel(`${response.data.showMore?.trackerClickCustom}`);
                    }
                });

                
                container.querySelector('#unicorn .footer-sponsor b')?.addEventListener(eventType, () => {
                    if ('coupon' in response.data) {
                        printCustomPixel(`${response.data.coupon?.trackerClickCustom}`);
                    }
                });
                

                container.querySelectorAll('.unicorn__logo').forEach((element: HTMLElement) => {
                    element.addEventListener(eventType, event => {
                        const element = event.target as HTMLElement;
                        if (element.classList.contains('adobe-logo') && 'logo' in response.data) {
                            printCustomPixel(`${response.data.logo?.trackerClickCustom}`);
                        } else if ('coupon' in response.data && typeof response.data.coupon === 'object') {
                            printCustomPixel(`${response.data.coupon?.trackerClickCustom}`);
                        }
                    });
                });
            });

            printCustomPixel(`${response.data.trackerImpCustom}&id_list=${stringEncode(elements.join(','))}`);
        })
        .catch(() => {});

    ww.view.showResults('.icons-search-empty-result');
};

const getBannerFromLibrary = (options: SponsorBannerSettings) => {
    /* eslint-disable @typescript-eslint/camelcase */
    const lang = VALID_LANGUAGES[LANGUAGE_SHORT] || LANGUAGE_SHORT;
    return Adobe({
        components: 'banner',
        country: USER_COUNTRY_CODE,
        params: {
            aspect_ratio: 1,
            image_type: 'vector',
            language: lang,
            words: options.term,
            page: document.getElementById('pagination-page')
                ? parseInt((document.getElementById('pagination-page') as HTMLInputElement)?.value)
                : 1,
                orientation: 'square',
                type: ['vector'],
                
        },
        placement: ww.view.section === '404' ? 404 : ww.view.section.includes('detail') ? 'idp' : 'srp',
        projectId: 'www.flaticon.com',
        redirectAndBase64: 'https://flat.toolsmeen.io/edge/redirect?url=',
        locale: USER_COUNTRY_CODE || 'us',
    });
};

const printCustomPixel = (params: string) => {
    new Image().src = `${BASE_URL}_ga?${params}&r=${new Date().getTime()}`;
};

const VALID_LANGUAGES: ValidLanguages = {
    br: 'pt',
    kr: 'ko',
};

type ValidLanguages = {
    [key: string]: string;
};
